import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Box, Link, Stack, TextField, Typography } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import CheckboxGroup from '../../components/form/checkbox';
import OrganizationService from '../../services/organizations';
import { useNavigate, useParams } from 'react-router-dom';
import PatientService from '../../services/patient';
import AlertBanner from '../../components/alerts';
import ErrorBanner from '../../components/alerts/errorBanner';
import { useDispatch } from 'react-redux';
import { CheckCircle } from '@mui/icons-material';

const OptOutOrganization = () => {
    const navigate = useNavigate()
    const { organizationCode } = useParams();
    const dispatch = useDispatch()

    const [countdown, setCountdown] = useState(5);

    const [isSuccessful, setIsSuccessful] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [organization, setOrganization] = useState(null)
    const [error, setError] = useState('')
    const [inputs, setInputs] = useState({
        phoneNumber: ""
    })

    const getOrganizationData = async () => {
        try {
            if (organizationCode === null || organizationCode === '') {
                navigate('/sms/org-not-found')
                return
            }
            const response = await OrganizationService.getOrganizationOptInInfo(organizationCode)
            const { result: organizationData } = response

            setOrganization(organizationData)
        } catch (error) {
            console.log(error)
            if (!error?.status)
                navigate('/sms/org-not-found')
        }
    }

    useEffect(() => {
        getOrganizationData()
    }, []);

    useEffect(() => {
        if (isSuccessful) {
            let timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 0) {
                        clearInterval(timer);
                        window.location.href = organization?.website; // Redirect after countdown reaches 0
                        return 0; // Ensure state doesn't go negative
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [isSuccessful]);

    const submitOptOut = async () => {
        try {
            setIsSubmiting(true)
            const response = await OrganizationService.optOutPatient({ ...inputs, organizationCode })
            setIsSuccessful(true)
            setIsSubmiting(false)
        } catch (error) {
            console.log(error)
            setIsSubmiting(false)
            setError(error?.message || "An error has occured. Please try again later.")
        }
    }

    const handleCredentials = (e) => {
        if (error != '')
            setError("")

        const { name, value } = e.target;

        if (name === 'phoneNumber') {
            const cleanedValue = value.replace(/\D/g, '');
            setInputs(prevInputs => ({
                ...prevInputs,
                [name]: cleanedValue.slice(0, 10)
            }));
        } else {
            setInputs(prevInputs => ({
                ...prevInputs,
                [name]: value
            }));
        }
    }

    return (
        isSuccessful
            ?
            <Stack
                spacing={2}
                direction="column"
                sx={{
                    width: '100%',
                    maxWidth: 400
                }}>
                <img
                    src={organization?.logo}
                    style={{
                        width: '100%'
                    }}
                />
                <Alert
                    variant="outlined"
                    icon={<CheckCircle fontSize="inherit" />}
                    severity="success">
                    <AlertTitle>You will be routed to {organization?.name}'s website in {countdown} seconds</AlertTitle>
                    You have successfully opted out of reminders!
                </Alert>

            </Stack>
            :
            <Stack
                spacing={2}
                direction="column"
                sx={{
                    width: '100%',
                    maxWidth: 400
                }}>
                <img
                    src={organization?.logo}
                    style={{
                        width: '100%'
                    }}
                />
                <Typography variant="h6">{organization?.optOutMessage}</Typography>
                <TextField
                    fullWidth
                    value={inputs.phoneNumber}
                    error={error != ''}
                    onChange={() => handleCredentials(event)}
                    name="phoneNumber"
                    label="Phone Number"
                />
                <LoadingButton
                    disabled={
                        inputs.phoneNumber === ''
                        ||
                        error != ""
                    }
                    onClick={() => submitOptOut()}
                    loading={isSubmiting}
                    fullWidth
                    variant='contained'
                >
                    OPT-OUT
                </LoadingButton>
                <ErrorBanner error={error} removeError={() => setError("")} />
            </Stack>
    )
}

export default OptOutOrganization
