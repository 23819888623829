import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AppLayout from '../../components/layouts/app'
import { Container, Typography } from '@mui/material'
import DashboardNavigation from '../../components/dashboard/navigation'

function Dashboard() {
  return (
    <AppLayout>
      <Container
        disableGutters={true}
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
        maxWidth="xl">
        <DashboardNavigation />
        <Outlet />
      </Container>
    </AppLayout>
  )
}

export default Dashboard
