import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './App.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation
} from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Login from './pages/login/login'
import SignUp from './pages/signup/signUp'
import LoginLoading from './pages/login/loginLoading'
import PropTypes from 'prop-types'
import NotFound from './pages/404/404'
import { useTheme } from '@emotion/react'
import SmsReminder from './pages/dashboard/smsReminder/index'
import UpdateColorLine from './pages/dashboard/smsReminder/update-color-line'
import UpdateScheduleTime from './pages/dashboard/smsReminder/update-schedule-time'
import Dashboard from './pages/dashboard'
import AddPatient from './pages/dashboard/smsReminder/add-patient'
import UploadPatientBulk from './pages/dashboard/smsReminder/upload-patient-bulk'
import SmsOptLayout from './components/layouts/sms/sms-opt'
import OptInOrganization from './pages/sms/optInOrganization'
import OptOutOrganization from './pages/sms/optOutOrganization'

function App() {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const theme = useTheme()
  const authReducer = useSelector((state) => state.authReducer)

  const Guard = ({ token, routeRedirect }) => {

    const location = useLocation()

    if (!authReducer?.user) {
      return <Navigate to='/' replace state={{ from: location }} />
    }

    return (
      <Outlet />
    )
    // else {
    //   if (!user?.isInvited) {
    //     return <Navigate to='/invite-code' replace state={{ from: location }} />
    //   }
    //   else if (!user?.isVerified) {
    //     return <Navigate to='/verifyOtp' replace state={{ from: location }} />
    //   }
    //   else if (user?.isVerified && user?.stage < 4) {
    //     if (user?.stage === 1 && !window.location.href.includes('/verifyOtp')) {
    //       return <Navigate to='/verifyOtp' replace state={{ from: location }} />
    //     }
    //     else if (user?.stage === 2 && !window.location.href.includes('/age-verification')) {
    //       return <Navigate to='/age-verification' replace state={{ from: location }} />
    //     }
    //   }
    // return localStorage.getItem(token)
    //   ? (
    //     <>
    //       <NotificationAppAlert />
    //       <Outlet />
    //     </>
    //   )
    //   : (
    //     <Navigate to={routeRedirect} replace state={{ from: location }} />
    //   )
  }

  Guard.propTypes = {
    token: PropTypes.string.isRequired,
    routeRedirect: PropTypes.string.isRequired
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 3000);
    return () => clearTimeout(timeout); // Cleanup function to clear timeout if component unmounts
  }, []);

  return (
    <div className="appWrap">
      <Router history={createBrowserHistory}>
        {
          //loading
          false
            ?
            (
              <LoginLoading />
            )
            : (
              <Routes>
                <Route exact path="/" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/sms" element={<SmsOptLayout />}>
                  <Route path="opt-in/:organizationCode" element={<OptInOrganization />} />
                  <Route path="opt-out/:organizationCode" element={<OptOutOrganization />} />
                  <Route path="*" element={<NotFound />} />
                </Route>
                <Route element={<Guard token="user" routeRedirect="/" />}>
                  <Route path="/dashboard" element={<Dashboard />}>
                    <Route index element={<Navigate to="sms-reminder" replace />} />
                    <Route path="sms-reminder" element={<SmsReminder />} />
                    <Route path="/dashboard/sms-reminder/upload-patient-bulk" element={<UploadPatientBulk />} />
                    <Route path="/dashboard/sms-reminder/add-patient" element={<AddPatient />} />
                    <Route path="/dashboard/sms-reminder/update-color-line" element={<UpdateColorLine />} />
                    <Route path="/dashboard/sms-reminder/update-schedule-time" element={<UpdateScheduleTime />} />
                  </Route>
                </Route>

                <Route path='*' element={<NotFound />} />
              </Routes>
            )}
      </Router>
    </div>
  )
}

export default App
