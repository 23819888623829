import { configureStore, combineReducers, compose, applyMiddleware } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk'
import authReducer from './reducers/auth'
import controllerReducer from './reducers/controller'

const persistConfig = {
  key: 'counter',
  storage
}

const reducers = combineReducers({
  authReducer,
  controllerReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    // localStorage.removeItem('user')
    // window.location = '/'
    // window.location.replace('/')
    return reducers(undefined, action)
  }

  return reducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default configureStore(
  { reducer: persistedReducer },
  composeEnhancers(applyMiddleware(thunkMiddleware))
)
