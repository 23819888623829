import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Box, Link, Stack, TextField, Typography } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import CheckboxGroup from '../../components/form/checkbox';
import OrganizationService from '../../services/organizations';
import { useNavigate, useParams } from 'react-router-dom';
import PatientService from '../../services/patient';
import AlertBanner from '../../components/alerts';
import ErrorBanner from '../../components/alerts/errorBanner';
import { useDispatch } from 'react-redux';
import { CheckCircle } from '@mui/icons-material';

const OptInOrganization = () => {
    const navigate = useNavigate()
    const { organizationCode } = useParams(); // Get the URL parameter
    const dispatch = useDispatch()
    // const params = queryParams.getAll(); // Extract "search" param
    const [countdown, setCountdown] = useState(5);

    const recaptchaRef = useRef(null);

    const [isSuccessful, setIsSuccessful] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [organization, setOrganization] = useState(null)
    const [isVerified, setIsVerified] = useState(false)
    const [error, setError] = useState('')
    const [inputs, setInputs] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        check: false
    })

    const checkHandle = () => {
        setInputs(prevInputs => ({
            ...prevInputs,
            check: !inputs.check
        }));
    }

    const getOrganizationData = async () => {
        try {
            if (organizationCode === null || organizationCode === '') {
                navigate('/sms/org-not-found')
                return
            }
            const response = await OrganizationService.getOrganizationOptInInfo(organizationCode)
            const { result: organizationData } = response
            console.log("RESPONSE RESPONSE ---", organizationData)
            setOrganization(organizationData)
        } catch (error) {
            console.log(error)
            if (!error?.status)
                navigate('/sms/org-not-found')
        }
    }

    useEffect(() => {
        getOrganizationData()
    }, []);


    useEffect(() => {
        if (isSuccessful) {
            let timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 0) {
                        clearInterval(timer);
                        window.location.href = organization?.website; // Redirect after countdown reaches 0
                        return 0; // Ensure state doesn't go negative
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [isSuccessful]);


    const submitOptIn = async () => {
        try {
            setIsSubmiting(true)
            const response = await OrganizationService.addPatient({ ...inputs, organizationCode })
            setIsSuccessful(true)
            setIsSubmiting(false)
        } catch (error) {
            console.log(error)
            setIsSubmiting(false)
            setError(error?.message || "An error has occured. Please try again later.")
        }
    }

    const resetPage = () => {
        setIsVerified(false)
    }

    const handleCredentials = (e) => {
        if (error != '')
            setError("")

        const { name, value } = e.target;

        // If the field is phoneNumber, only allow up to 10 digits.
        if (name === 'phoneNumber') {
            // Optionally remove any non-digit characters:
            const cleanedValue = value.replace(/\D/g, '');
            // Limit to 10 characters:
            setInputs(prevInputs => ({
                ...prevInputs,
                [name]: cleanedValue.slice(0, 10)
            }));
        } else {
            setInputs(prevInputs => ({
                ...prevInputs,
                [name]: value
            }));
        }
    }

    const onChange = async (value) => {
        try {
            const apiKey = "AIzaSyCkQVcWhN3ewwkIusLAMFYPF9U6l7e7wd0"
            const googleAPIKey = 'brightview-group-1741130716702'
            const response = await axios.post(`https://recaptchaenterprise.googleapis.com/v1/projects/${googleAPIKey}/assessments?key=${apiKey}`, {
                event: {
                    token: value,
                    expectedAction: "signup",
                    siteKey: "6LcNcvEqAAAAAOyoS1Q4-8Ecas0R0_fzQ3PlL_As",
                }
            })

            const riskAnalysis = response?.data?.riskAnalysis?.score

            const isVerified = riskAnalysis > 0.5

            if (!isVerified) {
                setError("Recaptcha score is low. Please refresh and try again")
                return
            }

            setIsVerified(true)

            console.log('response', response)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        isSuccessful
            ?
            <Stack
                spacing={2}
                direction="column"
                sx={{
                    width: '100%',
                    maxWidth: 400
                }}>
                <img
                    src={organization?.logo}
                    style={{
                        width: '100%'
                    }}
                />
                <Alert
                    variant="outlined"
                    icon={<CheckCircle fontSize="inherit" />}
                    severity="success">
                    <AlertTitle>You will be routed to {organization?.name}'s website in {countdown} seconds</AlertTitle>
                    All signed up for the daily colorline schedule!
                </Alert>

            </Stack>
            :
            <Stack
                spacing={2}
                direction="column"
                sx={{
                    width: '100%',
                    maxWidth: 400
                }}>
                <img
                    src={organization?.logo}
                    style={{
                        width: '100%'
                    }}
                />
                <Typography variant="h6">{organization?.optInMessage}</Typography>
                <Stack
                    direction="row"
                    spacing={2}>
                    <TextField
                        value={inputs.firstName}
                        error={error != ''}
                        onChange={() => handleCredentials(event)}
                        name="firstName"
                        label="First Name"
                    />
                    <TextField
                        value={inputs.lastName}
                        error={error != ''}
                        onChange={() => handleCredentials(event)}
                        name="lastName"
                        label="Last Name"
                    />
                </Stack>
                <TextField
                    fullWidth
                    value={inputs.phoneNumber}
                    error={error != ''}
                    onChange={() => handleCredentials(event)}
                    name="phoneNumber"
                    label="Phone Number"
                />
                <CheckboxGroup
                    checked={inputs?.check}
                    checkHandle={checkHandle}
                    label={
                        <>
                            By clicking, I am providing my electronic signature expressly authorizing {organization?.name} to contact me by email, phone or text (including an automatic dialing system or artificial/pre-recorded voice) at the home or cell phone number above. I understand I am not required to sign/agree to this as a condition to purchase.
                        </>
                    }
                />
                <ReCAPTCHA
                    onChange={onChange}
                    ref={recaptchaRef}
                    sitekey="6LcNcvEqAAAAAOyoS1Q4-8Ecas0R0_fzQ3PlL_As"
                />
                <LoadingButton
                    disabled={
                        inputs.firstName === ''
                        ||
                        inputs.lastName === ''
                        ||
                        inputs.phoneNumber === ''
                        ||
                        !inputs.check
                        ||
                        error != "" || !isVerified
                    }
                    onClick={() => submitOptIn()}
                    loading={isSubmiting}
                    fullWidth
                    variant='contained'
                >
                    OPT-IN
                </LoadingButton>
                <ErrorBanner error={error} removeError={() => setError("")} />
            </Stack>
    )
}

export default OptInOrganization
