import { Box, Container } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import LogoContainer from '../../header/logo-container'

const SmsOptLayout = ({ children }) => {
    return (
        <Container
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'column',
                py: 2
            }}>
            <LogoContainer mini />
            <Outlet />
            <Box sx={{
                opacity: 0
            }}>
                <LogoContainer mini />
            </Box>
        </Container>
    )
}

export default SmsOptLayout
