import api from "../config/axios"

const getDashboard = () => {
    return api.get('sms/dashboard')
}

const SmsService = {
    getDashboard
}

export default SmsService