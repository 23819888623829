import React, { useEffect, useState } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { deleteResetCSV } from "../../redux/actions/controller";

const CsvUpload = ({ onFileUpload }) => {
    const dispatch = useDispatch();
    const [file, setFile] = useState(null);
    const [inputKey, setInputKey] = useState(Date.now()); // Force re-render input field
    const { resetCSV } = useSelector((state) => state.controllerReducer);

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];

        if (uploadedFile && uploadedFile.type === "text/csv") {
            setFile(uploadedFile);
            readCSV(uploadedFile);
        } else {
            alert("Please upload a valid CSV file.");
        }
    };

    const csvToJson = (csvText) => {
        const lines = csvText.split("\n");
        const headers = lines[0].split(",");
        const result = lines.slice(1).map((line) => {
            const values = line.split(",");
            let obj = {};
            headers.forEach((header, index) => {
                obj[header?.trim()] = values?.[index]?.trim();
            });
            return obj;
        });
        return result;
    };

    const readCSV = (file) => {
        const reader = new FileReader();
        reader.onload = ({ target }) => {
            const text = target.result;
            const csvJson = csvToJson(text);
            onFileUpload(csvJson); // Pass CSV text to parent function
        };
        reader.readAsText(file);
    };

    useEffect(() => {
        console.log("resetCSV", resetCSV)
        if (resetCSV) {
            handleDelete();
            dispatch(deleteResetCSV());
        }
    }, [resetCSV]);

    const handleDelete = () => {
        setFile(null);
        onFileUpload(null)
        setInputKey(Date.now()); // Force re-render file input
    };

    return (
        <Box
            sx={{
                border: "2px dashed #ccc",
                padding: "20px",
                borderRadius: "8px",
                textAlign: "center",
                backgroundColor: "#fafafa",
                width: "100%",
                maxWidth: "400px",
                position: "relative",
            }}
        >
            <input
                key={inputKey} // Reset input field when file is deleted
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="csv-upload"
            />
            <label htmlFor="csv-upload">
                <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                >
                    Upload CSV
                </Button>
            </label>

            {file && (
                <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                        {file.name}
                    </Typography>
                    <IconButton onClick={handleDelete} color="error" sx={{ ml: 1 }}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default CsvUpload;