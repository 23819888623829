import React, { useEffect, useRef, useState } from 'react'
import DashboardItemLayout from '../../../components/layouts/dashboard/dashboardItemLayout'
import { Typography, Stack, Box, TextField, MenuItem, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ArrowForward } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import ColorLineService from '../../../services/colorline'
import { turnOnAnnouncement } from '../../../redux/actions/controller'
import { useDispatch } from 'react-redux'
import AlertBanner from '../../../components/alerts'

const UpdateColorLineItem = ({
    list,
    data,
    maxWidth,
    updateColorline,
    indexParent
}) => {
    return (
        <Stack
            direction="row"
            spacing={2}
            sx={{
                alignItems: 'center'
            }}
        >
            {/* Ensure all items use the same width */}
            <Box sx={{ width: maxWidth }}>
                <Typography>{data?.date}</Typography>
            </Box>
            <ArrowForward />
            <TextField
                onChange={(e) => updateColorline(e, indexParent)}
                select
                label="Select"
                defaultValue={data?.id}
                sx={{ minWidth: 170 }}
            >
                {list.map((item) => (
                    <MenuItem
                        key={`${indexParent}-${item?.id}`} // Unique key from the colorCode and index
                        value={item?.id}>
                        {item?.name}
                    </MenuItem>
                ))}
            </TextField>
        </Stack>
    )
}

const UpdateColorLine = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const textRef = useRef([]);
    const [maxWidth, setMaxWidth] = useState(100); // Default width
    const [isLoading, setIsLoading] = useState(''); // Default width

    const [error, setError] = useState(''); // Default width
    const [colorLineSchedule, setColorLineSchedule] = useState([]); // Default width
    const [allColors, setAllColors] = useState([]); // Default width
    const [isSaveLoading, setIsSaveLoading] = useState(false); // Default width
    const [initializedState, setInitializedState] = useState(null);

    useEffect(() => {
        if (textRef.current.length > 0) {
            const widths = textRef.current.map(el => el?.offsetWidth || 0);
            setMaxWidth(Math.max(...widths));
        }
    }, []);

    const getColorline = async () => {
        try {
            setIsLoading(true)
            const response = await ColorLineService.getColorlineSchedule()
            const {
                colorSchedule,
                allColors: allColorData,
            } = response?.result
            setAllColors(allColorData)
            setColorLineSchedule(colorSchedule)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    const updateColorline = (e, index) => {
        const valueItemId = e.target.value
        let temp = [...colorLineSchedule]
        const selectedColor = allColors?.find(item => item?.id === Number(valueItemId));
        temp[index] = {
            ...temp[index],
            ...selectedColor
        }
        setColorLineSchedule(temp)
    }

    const saveColorline = async () => {
        try {
            setIsSaveLoading(true)
            await ColorLineService.updateColorline(colorLineSchedule)
            dispatch(turnOnAnnouncement({
                title: "Colorline updated Successfully",
                type: "success"
            }))
            setIsSaveLoading(false)
        } catch (error) {
            setIsSaveLoading(false)
            console.log(error)
        }
    }

    const goBack = () => {
        navigate("/dashboard/sms-reminder")
    }

    useEffect(() => {
        getColorline()
    }, []);

    return (
        <DashboardItemLayout
            goBackText="Back to SMS Reminders Dashboard"
            goBackHandler={() => goBack()}
            title="Update Color Line"
        >
            {
                isLoading
                    ?
                    <Typography>Loading</Typography>
                    :
                    <Stack
                        spacing={4}
                        sx={{
                            alignItems: 'flex-start'
                        }}
                        direction="column">
                        <Stack
                            spacing={4}>
                            {
                                colorLineSchedule?.map((item, index) => (
                                    <UpdateColorLineItem
                                        indexParent={index}
                                        updateColorline={updateColorline}
                                        list={allColors}
                                        maxWidth={maxWidth}
                                        key={`${item.colorCode}-${index}`} // Unique key from the colorCode and index
                                        data={item}
                                    />
                                ))
                            }
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                        >
                            <LoadingButton
                                loading={isSaveLoading}
                                onClick={() => saveColorline()}
                                variant="contained">
                                Save Changes
                            </LoadingButton>
                        </Stack>
                        <AlertBanner />
                    </Stack>
            }
        </DashboardItemLayout>
    )
}

export default UpdateColorLine
