import { ArrowBack } from '@mui/icons-material'
import { Container, Typography, Button } from '@mui/material'
import React from 'react'

const DashboardItemLayout = ({
    children,
    title,
    goBackHandler,
    goBackText
}) => {
    return (
        <Container
            sx={{
                py: 12,
                ml: "350px"
            }}>
            {
                goBackText
                &&
                <Button
                    startIcon={<ArrowBack fontSize="small" />}
                    onClick={() => goBackHandler()}
                    variant="text"
                >
                    Go Back
                </Button>
            }
            <Typography>{title}</Typography>
            {children}
        </Container>
    )
}

export default DashboardItemLayout
