import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

const CheckboxGroup = ({ label, checked, checkHandle }) => {
    return (
        <FormControlLabel
            sx={{ m: 0 }}
            onChange={checkHandle}
            control={<Checkbox checked={checked} />}
            label={label}
        />
    );
};

export default CheckboxGroup;