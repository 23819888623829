import api from "../config/axios"

const getColorlineSchedule = () => {
    return api.get('colorline/get')
}

const updateColorline = (colorSchedule) => {
    return api.put('colorline/schedule', colorSchedule)
}

const getColorlineTimeSchedule = () => {
    return api.get('colorline/schedule-time/get')
}

const updateColorlineTimeSchedule = (timestamp) => {
    return api.post('colorline/schedule-time/update', { timestamp })
}

const ColorLineService = {
    getColorlineTimeSchedule,
    getColorlineSchedule,
    updateColorline,
    updateColorlineTimeSchedule
}

export default ColorLineService