import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

const AppLayout = ({ children, hideNavigation }) => {
    const location = useLocation();
    return (
        <Box sx={{
            overflow: 'hidden',
            boxSizing: 'border-box'
        }}>
            {children}
        </Box>
    )
}

export default AppLayout
