import axios from 'axios';
import store from '../redux/store';

const isProduction = process.env.NODE_ENV === 'production';

const api = axios.create({
  baseURL: isProduction
    ? 'https://api.betzon.com/api/v1/' // Production API
    : 'http://172.20.10.13:2040/api/v1/', // Development API
  timeout: 10000, // Lowered timeout to prevent excessive delays
  headers: {
    'Content-Type': 'application/json',
    platform: 'web',
    'x-api-key': "mb52ea2d-4567-4956-9d19-35a7e75a2c17" //process.env.REACT_APP_API_KEY // Move API key to .env file
  },
  withCredentials: true
});

api.interceptors.request.use(
  async (config) => {
    console.log(`📡 SENDING REQUEST: ${config.method.toUpperCase()} ${config.url}`);
    return config;
  },
  (error) => {
    console.error('🚨 Request Error:', error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response?.data || response,
  async (error) => {
    if (error?.response) {
      console.error(`🚨 API ERROR ${error.response.status}: ${error.config.url}`);

      if (error.response.status === 401) {
        console.warn('🔄 Unauthorized! Redirecting to login...');
        store.dispatch({ type: 'RESET_AUTH' });
      }

      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
);

export default api;