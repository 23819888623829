import api from "../config/axios"

const addPatient = (patientData) => {
    return api.post('patients/add', patientData)
}

const addPatientBulk = (patientData) => {
    return api.post('patients/add/bulk', patientData)
}

const PatientService = {
    addPatient,
    addPatientBulk
}

export default PatientService