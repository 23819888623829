import { ArrowForward } from '@mui/icons-material';
import { Chip, Stack, Typography, Box } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { colorSchedule } from '../../../../config/content';

const ColorScheduleItem = ({ data, maxWidth }) => {
    return (
        <Stack
            direction="row"
            spacing={2}
            sx={{
                alignItems: 'center',
            }}
        >
            {/* Ensure all items use the same width */}
            <Box sx={{ width: maxWidth }}>
                <Typography>{data?.date}</Typography>
            </Box>
            <ArrowForward />
            <Chip
                label={data?.name?.toUpperCase()}
                size="small"
                sx={{ fontWeight: 800, backgroundColor: data?.value, color: 'white' }}
            />
        </Stack>
    );
};

const ColorSchedule = ({
    data
}) => {
    const textRef = useRef([]);
    const [maxWidth, setMaxWidth] = useState(100); // Default width

    useEffect(() => {
        if (textRef.current.length > 0) {
            // Find the max width dynamically based on rendered text
            const widths = textRef.current.map(el => el?.offsetWidth || 0);
            setMaxWidth(Math.max(...widths));
        }
    }, []);

    return (
        <Stack
            sx={{
                alignItems: 'flex-start'
            }}
        >
            {/* Render items initially and capture text width */}
            {data?.map((item, index) => (
                <Box key={index} ref={el => textRef.current[index] = el} sx={{ visibility: 'hidden', position: 'absolute' }}>
                    <Typography>{item.date}</Typography>
                </Box>
            ))}

            {/* Render items again with computed max width */}
            {data?.map((item, index) => (
                <ColorScheduleItem key={index} data={item} maxWidth={maxWidth} />
            ))}

        </Stack>
    );
};

export default ColorSchedule;