
const initialState = {}
// Use the initialState as a default value
export default function authReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case 'USER_LOGIN': {
      return {
        ...state,
        user: action.payload
      }
    }

    case 'RESET_AUTH': {
      return initialState
    }

    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state
  }
}
