import React, { useEffect, useRef, useState } from 'react'
import DashboardItemLayout from '../../../components/layouts/dashboard/dashboardItemLayout'
import { Typography, Stack, Box, TextField, MenuItem, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CsvUpload from '../../../components/upload/csv'
import PatientService from '../../../services/patient'
import { LoadingButton } from '@mui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { resetCSV, turnOnAnnouncement } from '../../../redux/actions/controller'
import AlertBanner from '../../../components/alerts'
import ErrorBanner from '../../../components/alerts/errorBanner'

const UploadPatientBulk = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [dataToUpload, setDataToUpload] = useState(null)
    const [isUploadLoading, setIsUploadLoading] = useState(false)
    const [error, setError] = useState('')

    const uploadCSV = async () => {
        try {
            setIsUploadLoading(true)
            const response = await PatientService.addPatientBulk(dataToUpload)
            console.log(response)
            dispatch(turnOnAnnouncement({
                title: "Patients Successfully Uploaded",
                type: "success"
            }))
            setIsUploadLoading(false)
        } catch (error) {
            setIsUploadLoading(false)
            setError(error?.message)
            console.log(error)
        }
    }

    const resetCSVHandler = () => {
        dispatch(resetCSV())
    }

    return (
        <DashboardItemLayout
            goBackText="Back to SMS Reminders Dashboard"
            goBackHandler={() => navigate("/dashboard/sms-reminder")}
            title="Upload Patients in Bulk"
        >
            <ErrorBanner error={error} removeError={() => setError("")} />
            <Stack
                spacing={4}
                sx={{
                    alignItems: 'flex-start'
                }}
                direction="column">
                <Stack
                    direction="row"
                    spacing={2}>
                    <CsvUpload
                        onFileUpload={(csvJson) => setDataToUpload(csvJson)}
                    />
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <Button
                        onClick={() => resetCSVHandler()}
                        variant="outline">
                        Cancel
                    </Button>
                    <LoadingButton
                        disabled={!dataToUpload}
                        loading={isUploadLoading}
                        onClick={() => uploadCSV()}
                        variant="contained">
                        Upload CSV
                    </LoadingButton>
                </Stack>
                <AlertBanner />
            </Stack>
        </DashboardItemLayout>
    )
}

export default UploadPatientBulk
