
const initialState = {
  announcement: {
    status: false
  },
  resetCSV: false
}
// Use the initialState as a default value
export default function controllerReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    // Do something here based on the different types of actions
    case 'TURN_ON_ANNOUNCEMENT': {
      return {
        ...state,
        announcement: {
          status: true,
          ...action.payload
        }
      }
    }

    case 'TURN_OFF_ANNOUNCEMENT': {
      return {
        ...state,
        announcement: {
          status: false
        }
      }
    }

    case 'RESET_CSV': {
      return {
        ...state,
        resetCSV: true
      }
    }

    case 'DELETE_RESET_CSV': {
      return {
        ...state,
        resetCSV: false
      }
    }

    default:
      return state
  }
}
