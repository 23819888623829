import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Cloud from '@mui/icons-material/Cloud';
import LogoContainer from '../header/logo-container';
import { Stack } from '@mui/material';
import { dashboardNavigationItems } from '../../config/content';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';

const NavigationItem = ({ data, status, onClick }) => {
    return (
        <MenuItem
            onClick={() => onClick(data?.name)}
            selected={status}>
            <ListItemIcon>
                {data?.icon}
            </ListItemIcon>
            <ListItemText>{data?.title}</ListItemText>
        </MenuItem>
    )
}

const DashboardNavigation = () => {
    const navigate = useNavigate()
    const location = useLocation();


    const doesMatchRoute = (name) => {
        const location = useLocation();
        const segments = location.pathname.split("/").filter(Boolean); // Remove empty segments

        if (segments.length > 1) {
            return segments[1] === name; // Check if the segment right after "dashboard" matches
        }

        return false; // Default to false if there's no valid segment
    };

    const NavItemHandler = (path) => {
        navigate(`/dashboard/${path}`)
    }

    return (
        <Paper
            sx={{
                width: 320,
                maxWidth: '100%',
                height: '100%',
                pt: 6,
                borderBottom: 0,
                position: "fixed"
            }}>
            <Stack
                spacing={2}
                direction="column">
                <Stack
                    direction="row"
                    sx={{
                        alignItems: 'center',
                        justifyCenter: 'center'
                    }}>
                    <LogoContainer mini />
                    <Typography variant='h6'>BrightView</Typography>
                </Stack>
                <MenuList>
                    {
                        dashboardNavigationItems?.map((item, index) => (
                            <NavigationItem
                                onClick={(path) => NavItemHandler(path)}
                                status={doesMatchRoute(item?.name)}
                                key={index}
                                data={item} />
                        ))
                    }
                    <Divider />
                    <MenuItem>
                        <ListItemIcon>
                            <Cloud fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Web Clipboard</ListItemText>
                    </MenuItem>
                </MenuList>
            </Stack>
        </Paper>
    )
}

export default DashboardNavigation
