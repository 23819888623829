import React, { useEffect, useRef, useState } from 'react'
import DashboardItemLayout from '../../../components/layouts/dashboard/dashboardItemLayout'
import { Typography, Stack, Box, TextField, MenuItem, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { colors, colorSchedule } from '../../../config/content'
import { ArrowForward } from '@mui/icons-material'
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs'
import ColorLineService from '../../../services/colorline'
import { convertToDayjsTime, formatTimeForTimetz } from '../../../helper';
import { LoadingButton } from '@mui/lab'
import AlertBanner from '../../../components/alerts'
import { useDispatch } from 'react-redux'
import { turnOnAnnouncement } from '../../../redux/actions/controller'

const UpdateScheduleTime = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const textRef = useRef([]);
    const [maxWidth, setMaxWidth] = useState(100); // Default width
    const [selectedTime, setSelectedTime] = useState(dayjs());
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)

    useEffect(() => {
        if (textRef.current.length > 0) {
            const widths = textRef.current.map(el => el?.offsetWidth || 0);
            setMaxWidth(Math.max(...widths));
        }
    }, []);

    const getColorlineTime = async () => {
        try {
            const response = await ColorLineService.getColorlineTimeSchedule()
            const { result } = response
            setSelectedTime(convertToDayjsTime(result))
        } catch (error) {
            console.log(error)
        }
    }

    const updateColorlineTime = async () => {
        try {
            setIsUpdateLoading(true)
            const timeToSave = formatTimeForTimetz(selectedTime)
            const response = await ColorLineService.updateColorlineTimeSchedule(timeToSave)
            dispatch(turnOnAnnouncement({
                title: "Colorline Scheduled Reminder Time Updated",
                type: "success"
            }))
            setIsUpdateLoading(false)
        } catch (error) {
            setIsUpdateLoading(false)
            console.log(error)
        }
    }

    const goBack = () => {
        navigate("/dashboard/sms-reminder")
    }

    useEffect(() => {
        getColorlineTime()
    }, []);

    return (
        <DashboardItemLayout
            goBackText="Back to SMS Reminders Dashboard"
            goBackHandler={() => goBack()}
            title="Schedule Time for Colorline SMS"
        >
            <Stack
                spacing={2}
                sx={{ alignItems: 'flex-start' }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        label="Color Schedule SMS Time"
                        value={selectedTime}
                        onChange={(newValue) => setSelectedTime(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <Stack
                    spacing={4}
                    sx={{
                        alignItems: 'flex-start'
                    }}
                    direction="column">
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <Button
                            onClick={() => goBack()}
                            variant="outline">
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={isUpdateLoading}
                            onClick={() => updateColorlineTime()}
                            variant="contained">
                            Save Changes
                        </LoadingButton>
                    </Stack>
                    <AlertBanner />
                </Stack>
            </Stack>
        </DashboardItemLayout>
    )
}

export default UpdateScheduleTime
