import React, { useEffect, useRef, useState } from 'react'
import DashboardItemLayout from '../../../components/layouts/dashboard/dashboardItemLayout'
import { Typography, Stack, Box, TextField, MenuItem, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { colors, colorSchedule } from '../../../config/content'
import { ArrowForward } from '@mui/icons-material'
import PatientService from '../../../services/patient'
import { LoadingButton } from '@mui/lab'
import AlertBanner from '../../../components/alerts'
import { useDispatch } from 'react-redux'
import { turnOnAnnouncement } from '../../../redux/actions/controller'
import ErrorBanner from '../../../components/alerts/errorBanner'
import { isObjectValid } from '../../../helper'
import OrganizationService from '../../../services/organizations'

const AddPatient = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isUploadLoading, setIsUploadLoading] = useState(false)
    const [error, setError] = useState("")

    const [inputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: ''
    })

    const resetInputs = () => setInputs({
        firstName: '',
        lastName: '',
        phoneNumber: ''
    })

    const goBack = () => {
        resetInputs()
        navigate("/dashboard/sms-reminder")
    }

    const inputHandler = (e) => {
        const { name, value } = e.target;

        // If the field is phoneNumber, only allow up to 10 digits.
        if (name === 'phoneNumber') {
            // Optionally remove any non-digit characters:
            const cleanedValue = value.replace(/\D/g, '');
            // Limit to 10 characters:
            setInputs(prevInputs => ({
                ...prevInputs,
                [name]: cleanedValue.slice(0, 10)
            }));
        } else {
            setInputs(prevInputs => ({
                ...prevInputs,
                [name]: value
            }));
        }
    };

    const addPatientHandler = async () => {
        try {
            setIsUploadLoading(true)
            const response = await OrganizationService.addPatient(inputs)
            dispatch(turnOnAnnouncement({
                title: "Patient Successfully Added",
                type: "success"
            }))
            resetInputs()
            setIsUploadLoading(false)
        } catch (error) {
            setIsUploadLoading(false)
            setError(error?.message)
            console.log(error)
        }
    }

    return (
        <DashboardItemLayout
            goBackText="Back to SMS Reminders Dashboard"
            goBackHandler={() => goBack()}
            title="Add Patient"
        >
            <ErrorBanner error={error} removeError={() => setError("")} />
            <Stack
                spacing={4}
                sx={{
                    alignItems: 'flex-start'
                }}
                direction="column">
                <Stack
                    direction="row"
                    spacing={2}>
                    <TextField
                        type="text"
                        value={inputs.firstName}
                        onChange={(e) => inputHandler(e)}
                        name="firstName"
                        label="John"
                    />
                    <TextField
                        type="text"
                        value={inputs.lastName}
                        onChange={(e) => inputHandler(e)}
                        name="lastName"
                        label="Doe"
                    />
                    <TextField
                        type="phone"
                        value={inputs.phoneNumber}
                        onChange={(e) => inputHandler(e)}
                        name="phoneNumber"
                        label="8183331234"
                    />
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <Button
                        onClick={() => goBack()}
                        variant="outline">
                        Cancel
                    </Button>
                    <LoadingButton
                        disabled={isObjectValid(inputs) || error != ""}
                        onClick={() => addPatientHandler()}
                        loading={isUploadLoading}
                        variant="contained">
                        Save Changes
                    </LoadingButton>
                </Stack>
                <AlertBanner />
            </Stack>
        </DashboardItemLayout>
    )
}

export default AddPatient
