// export const logout = () => {
//   return (dispatch) => {
//     dispatch({
//       type: USER_LOGOUT,
//       payload: {}
//     })
//     localStorage.clear()
//     // window.location.href = '/'
//   }
// }

export const loginUser = (profile) => {
    return (dispatch) => {
        dispatch({
            type: 'USER_LOGIN',
            payload: profile
        })
    }
}

// export const commentOnWagerNotifcation = createAsyncThunk(
//   '/wagers/comment',
//   async (wagerId, thunkAPI) => {
//     try {
//       const data = await WagersServices.commentWagerNotification(wagerId)
//     } catch (error) {
//       console.log(error)
//       if (error.response.status === 401) {
//         localStorage.clear()
//         window.location.href = '/'
//       }
//       const message =
//         (error.response.data.status && error.response.data.errors[0]) || (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString()
//       return thunkAPI.rejectWithValue()
//     }
//   }
// )