import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'light'
    },
    containers: {
        main: {
            width: "100%",
        }
    },
    tab: {
        width: '40%',
        fontWeight: 700,
        fontSize: '12px',
        paddingLeft: 1,
        paddingRight: 1,
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: '36px', // Set your desired height here
    },
    tabs: {
        minHeight: '10px', // Set your desired height here
        height: 'fit-content',
        width: '100%',
        '.MuiTabs-scrollButtons': {
            width: '24px',
        },
        '.MuiTabs-scroller': {
            scrollBehavior: 'smooth'
        }
    },
    // typography: {
    //     // Override text colors to be light for better contrast and set fontWeight to 700 for all variants
    //     allVariants: {
    //         color: 'black', // Standard light text color for dark mode
    //         fontWeight: 700, // Apply fontWeight 700 for all text variants
    //     },
    // },
    components: {
        Input: {
            baseStyle: {
                field: {
                    _autofill: {
                        boxShadow: 'none !important',
                        backgroundColor: 'transparent !important',
                        WebkitBoxShadow: '0 0 0px 1000px #ffffff inset !important',
                        WebkitTextFillColor: '#000 !important', // Adjust text color as needed
                        transition: 'background-color 5000s ease-in-out 0s !important',
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    height: '2px',
                    backgroundColor: 'gray',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 700, // Ensure this remains 700 for button text
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    fontWeight: 700, // Ensure alerts also have fontWeight 700
                },
            },
        },
        // Customizing MuiBottomNavigationAction
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    // Apply fontWeight 700 and transform label text to uppercase
                    fontWeight: 700,
                    textTransform: 'uppercase',
                },
                label: {
                    // Ensure label text transformation and weight applies when the action is selected as well
                    '&.Mui-selected': {
                        fontWeight: 700,
                        textTransform: 'uppercase',
                    },
                },
            },
        },
    }
});

export default theme;
