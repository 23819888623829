import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const convertToTime = (timestamp) => {
  if (!timestamp) return '';

  let fullISO = '';

  // Case 1: Full ISO string (contains "T")
  if (timestamp.includes('T')) {
    fullISO = timestamp;
  } else {
    // Case 2: Time-only string with offset, e.g., "18:18:52.54-08"
    // Use a regex to capture the timezone offset components.
    const timeRegex = /([+-]\d{2})(?::?(\d{2}))?$/;
    const match = timestamp.match(timeRegex);
    let fixedTime = timestamp;
    if (match) {
      const offsetHour = match[1]; // e.g., "-08"
      const offsetMin = match[2] || '00'; // e.g., "00" if missing
      const fixedOffset = `${offsetHour}:${offsetMin}`; // e.g., "-08:00"
      fixedTime = timestamp.replace(timeRegex, fixedOffset);
    } else {
      // If no offset is provided, assume UTC.
      fixedTime = timestamp + '+00:00';
    }
    // Prepend today's date (in ISO format) to the fixed time.
    const today = new Date().toISOString().split('T')[0];
    fullISO = `${today}T${fixedTime}`;
  }

  // Create a Date object from the full ISO string.
  const date = new Date(fullISO);

  const options = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    // Auto-detect user's timezone.
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: 'short' // Include timezone abbreviation (e.g., "PDT")
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const isObjectValid = (obj) => {
  const invalidFields = Object.keys(obj).filter(key => 
      obj[key] === "" || obj[key] === null || obj[key] === false
  );

  //return invalidFields.length > 0 ? { valid: false, missingFields: invalidFields } : { valid: true };
  return invalidFields.length > 0 ? true : false;
};


export const convertToDayjsTime = (timestamp) => {
  if (!timestamp) return null;

  let fullISO = '';

  // Case 1: Full ISO string (contains 'T')
  if (timestamp.includes('T')) {
    fullISO = timestamp;
  } else {
    // Case 2: Time-only string with offset, e.g., "18:18:52.54-08"
    // Use a regex to capture the timezone offset parts.
    const timeRegex = /([+-]\d{2})(?::?(\d{2}))?$/;
    let fixedTime = timestamp;
    const match = timestamp.match(timeRegex);
    if (match) {
      const offsetHour = match[1]; // e.g., "-08"
      const offsetMin = match[2] || '00';
      const fixedOffset = `${offsetHour}:${offsetMin}`; // e.g., "-08:00"
      fixedTime = timestamp.replace(timeRegex, fixedOffset);
    } else {
      // If no offset is provided, assume UTC.
      fixedTime = timestamp + '+00:00';
    }
    // Prepend today's date.
    const today = dayjs().format('YYYY-MM-DD');
    fullISO = `${today}T${fixedTime}`;
  }

  // Parse the full ISO string as UTC, then convert to local time.
  return dayjs.utc(fullISO).local();
};

export const formatTimeForTimetz = (timestamp) => {
  if (!timestamp) return '';

  // Convert the ISO timestamp to a Day.js object in local time.
  const localTime = dayjs(timestamp).local();

  // Format the time portion including milliseconds and timezone offset.
  // "HH:mm:ss.SSSZ" produces a string like "03:18:52.540-07:00".
  return localTime.format("HH:mm:ss.SSSZ");
};