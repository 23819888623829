import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import withRouter from '../../helper'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../../redux/actions/auth.js'
import { Box, Button, Container, Divider, Stack, TextField, Typography } from '@mui/material'
//import { LoadingButton } from '@mui/lab'
import LogoContainer from '../../components/header/logo-container'
import AuthLayout from '../../components/layouts/auth'
import AuthService from '../../services/auth'
import { LoadingButton } from '@mui/lab';

function Login() {

  const authReducer = useSelector((state) => state.authReducer)

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [inputs, setInputs] = useState({
    email: '',
    password: ''
  })

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleCredentials = (event) => {
    if (error != '')
      setError("")
    let temp = { ...inputs }
    temp[event.target.name] = event.target.value
    setInputs(temp)
  }

  const hitLoginApi = async () => {
    try {
      setIsLoading(true)
      const response = await AuthService.login(inputs)
      const profile = response?.result
      dispatch(loginUser(profile))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
      setError(error?.message || "")
    }
  }

  useEffect(() => {
    if (authReducer?.user)
      navigate('/dashboard')
  }, [authReducer]);

  return (
    <AuthLayout>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '24px',
          boxSizing: 'border-box'
        }}>

        <LogoContainer />

        <TextField
          sx={{
            width: '100%'
          }}
          error={error != ''}
          name="email"
          onChange={() => handleCredentials(event)}
          variant='filled'
          label="Email"
        />

        <Stack spacing={1} sx={{ width: '100%' }}>
          <TextField
            sx={{
              width: '100%'
            }}
            error={error != ''}
            type='password'
            name="password"
            onChange={() => handleCredentials(event)}
            variant='filled'
            label="Password"
          />
          {
            error != ''
            &&
            <Typography variant='body2' color="error" sx={{ fontWeight: 400 }}>{error}</Typography>
          }
          <Link to="/reset-password"><Typography variant='body2' sx={{ textDecoration: 'underline' }}>Forgot password?</Typography></Link>
        </Stack>

      </Box>

      <Stack
        spacing={1}
        sx={{
          width: '100%'
        }}>

        <LoadingButton
          loading={isLoading}
          onClick={() => hitLoginApi()}
          disabled={inputs.email === '' || inputs.password === '' || error != ""}
          sx={{
            width: '100%'
          }}
          size="large"
          variant='contained'>
          Login
        </LoadingButton>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px'
        }}>
          <Typography variant='body2'>Already have an account?</Typography>
          <Link to='/signup'><Typography variant='body2' sx={{ textDecoration: 'underline' }}>Sign up</Typography> </Link>
        </Box>

      </Stack>

    </AuthLayout>
  )
}

export default Login