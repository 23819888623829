import { Alert, AlertTitle, Box, Collapse, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { turnOffAnnouncement } from '../../redux/actions/controller';
import CloseIcon from '@mui/icons-material/Close';

const AlertBanner = () => {
    const disptach = useDispatch()
    const announcement = useSelector((state) => state.controllerReducer.announcement)

    useEffect(() => {
        if (announcement?.status) {
            setTimeout(() => {
                disptach(turnOffAnnouncement())
            }, 4000);
        }
    }, [announcement]);

    return (
        <Box sx={{ width: '100%' }}>
            <Collapse in={announcement?.status}>
                <Alert
                    severity={announcement?.type || "success"}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                disptach(turnOffAnnouncement())
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >

                    {
                        announcement?.heroTitle
                        &&
                        <AlertTitle>{announcement?.heroTitle}</AlertTitle>
                    }
                    {announcement?.title}
                </Alert>
            </Collapse>
        </Box>
    )
}

export default AlertBanner
