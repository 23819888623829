import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
//import { LoadingButton } from '@mui/lab'
import LogoContainer from '../../components/header/logo-container'
import AuthLayout from '../../components/layouts/auth'
import { Box, Button, Container, Divider, Stack, TextField, Typography } from '@mui/material'

function SignUpScreen() {

  const [inputs, setInputs] = useState({
    email: '',
    username: '',
    password: '',
    accessCode: ''
  })

  const [error, setError] = useState({
    status: false,
    message: ''
  })

  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const SignUp = useSelector((state) => state.Auth)

  const hitSignUPAPI = (e) => {
    e.preventDefault()
  }

  const handleCredentials = (event) => {
    setError({
      status: false,
      message: ''
    })
    let temp = { ...inputs }
    temp[event.target.name] = event.target.value
    setInputs(temp)
  }

  useEffect(() => {
    if (!id && false) {
      console.log('NO ID')
      navigate('/beta')
    }
  }, []);


  return (
    <AuthLayout>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          //bgcolor: 'magenta',
          gap: '24px',
        }}>

        <LogoContainer />

        <TextField
          sx={{
            width: '100%'
          }}
          error={error.status}
          name="accessCode"
          onChange={() => handleCredentials(event)}
          variant='filled'
          label="Enter Access Code"
        />

        <TextField
          sx={{
            width: '100%'
          }}
          error={error.status}
          name="email"
          onChange={() => handleCredentials(event)}
          variant='filled'
          label="Enter your email"
        />

        <TextField
          sx={{
            width: '100%'
          }}
          error={error.status}
          name="username"
          onChange={() => handleCredentials(event)}
          variant='filled'
          label="Create a username"
        />

        <Stack spacing={1} sx={{ width: '100%' }}>
          <TextField
            sx={{
              width: '100%'
            }}
            error={error.status}
            type='password'
            name="password"
            onChange={() => handleCredentials(event)}
            variant='filled'
            label="Password"
          />
          {
            error.status ?
              <Typography variant='body2' color="error" sx={{ fontWeight: 400 }}>{error.message}</Typography> : ''
          }
        </Stack>

      </Box>

      <Stack
        spacing={2}
        sx={{
          width: '100%'
        }}>

        <Button
          onClick={() => hitSignUPAPI(event)}
          disabled={inputs.username === '' || inputs.password === '' || error.status}
          sx={{
            width: '100%'
          }}
          size="large"
          variant='contained'>
          Create an account
        </Button>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px'
        }}>
          <Typography variant='body2'>Already have an account?</Typography>
          <Typography variant='body2' sx={{ textDecoration: 'underline' }} onClick={() => navigate('/')}>Login</Typography>
        </Box>

      </Stack>
    </AuthLayout>
  )
}

export default SignUpScreen