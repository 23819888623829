import api from "../config/axios"

const getOrganizationOptInInfo = (organizationCode) => {
    return api.get(`organization/opt-in/${organizationCode}`)
}

const addPatient = (patientData) => {
    return api.post('organization/add', patientData)
}

const optOutPatient = (patientData) => {
    return api.post('organization/opt-out', patientData)
}


const OrganizationService = {
    getOrganizationOptInInfo,
    addPatient,
    optOutPatient
}

export default OrganizationService