import MessageIcon from '@mui/icons-material/Message';
export const dashboardNavigationItems = [
    {
        title: "SMS Reminders",
        icon: <MessageIcon fontSize="small" />,
        name: 'sms-reminder'
    }
]

export const colors = [
    { value: 0, label: 'Closed' },
    { value: 1, label: 'Blue' },
    { value: 2, label: 'Red' },
    { value: 3, label: 'Green' },
    { value: 4, label: 'Purple' },
    { value: 5, label: 'Magenta' },
    { value: 6, label: 'Pink' },
    { value: 7, label: 'Orange' },
];

export const colorSchedule = [
    {
        date: "Sunday",
        isOpen: true,
        color: 'blue'
    },
    {
        date: "Monday",
        isOpen: true,
        color: 'red'
    },
    {
        date: "Tuesday",
        isOpen: true,
        color: 'green'
    },
    {
        date: "Wednesday",
        isOpen: true,
        color: 'purple'
    },
    {
        date: "Thursday",
        isOpen: true,
        color: 'magenta'
    },
    {
        date: "Friday",
        isOpen: true,
        color: 'pink'
    },
    {
        date: "Saturday",
        isOpen: false,
        color: 'orange'
    },
    {
        date: "Saturday",
        isOpen: false,
        color: 'orange'
    },
    {
        date: "Saturday",
        isOpen: false,
        color: 'orange'
    }
]