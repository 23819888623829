import React from 'react'
import { Alert, Box, Collapse, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

const ErrorBanner = ({ error, removeError }) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Collapse in={error != ""}>
                {
                    removeError
                        ?

                        <Alert
                            severity={"error"}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        removeError()
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                        >
                            {error}
                        </Alert>
                        :
                        <Alert
                            severity={"error"}
                            sx={{ mb: 2 }}
                        >
                            {error}
                        </Alert>
                }
            </Collapse>
        </Box>
    )
}

export default ErrorBanner
